import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';
//import { AutoLoginGuard } from './guards/auto-login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canLoad: [IntroGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'search/:keyword',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  /*{ equal to home...
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },*/
  {
    path: 'category/:id',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule)
  },
  {
    path: 'courses/:category/:id',
    loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesPageModule)
  },
  {
    path: 'courses/:id',
    loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesPageModule)
  },
  {
    path: 'course/:id',
    loadChildren: () => import('./pages/course/course.module').then(m => m.CoursePageModule)
  },
  {
    path: 'course/:category/:id',
    loadChildren: () => import('./pages/course/course.module').then(m => m.CoursePageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./pages/impressum/impressum.module').then(m => m.ImpressumPageModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'advantages',
    loadChildren: () => import('./pages/profile/advantages/advantages.module').then(m => m.AdvantagesPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/profile/register/register.module').then(m => m.RegisterPageModule)
  },
  /*{
    path: 'login',
    loadChildren: () => import('./pages/profile/login/login.module').then(m => m.LoginPageModule)
  },*/
  {
    path: 'login',
    loadChildren: () => import('./pages/profile/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/profile/logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'mysearches',
    loadChildren: () => import('./pages/mysearches/mysearches.module').then(m => m.MysearchesPageModule)
  },
  {
    path: 'myfavorites',
    loadChildren: () => import('./pages/myfavorites/myfavorites.module').then(m => m.MyfavoritesPageModule)
  },
  {
    path: 'mylearningpath',
    loadChildren: () => import('./pages/mylearningpath/mylearningpath.module').then(m => m.MylearningpathPageModule)
  },
  {
    path: 'myfilter',
    loadChildren: () => import('./pages/myfilter/myfilter.module').then(m => m.MyfilterPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'open-issues',
    loadChildren: () => import('./pages/open-issues/open-issues.module').then( m => m.OpenIssuesPageModule)
  },
  {
    path: 'sorter',
    loadChildren: () => import('./sorter/sorter.module').then( m => m.SorterPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  __apiUrl: 'http://127.0.0.1:3000/api/v1/',
  _apiUrl: 'https://api.smalostage.mit.de/api/v1/',
  apiUrl: 'https://api.smalotest.mit.de/api/v1/',
  mediaUrl: 'https://api.smalotest.mit.de/assets/',
  socialShareOption: [
    {
      title: 'Facebook',
      logo: 'assets/socialshare/facebook.png',
      href: 'https://facebook.com/sharer/sharer.php?u=http://farm8.staticflickr.com/7027/6851755809_df5b2051c9_z.jpg'
    },
    {
      title: 'Twitter',
      logo: 'assets/socialshare/twitter.png',
      href: 'https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&url=http://farm8.staticflickr.com/7027/6851755809_df5b2051c9_z.jpg'
    },
    /*{
      title: 'Pinterest',
      logo: 'assets/socialshare/pinterest.png',
      href: 'https://pinterest.com/pin/create/link/?url=http://farm8.staticflickr.com/7027/6851755809_df5b2051c9_z.jpg&media=http://farm8.staticflickr.com/7027/6851755809_df5b2051c9_z.jpg&description=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.'
    },*/
    {
      title: 'Email',
      logo: 'assets/socialshare/mail.png',
      href2: 'mailto:receipient@user.com?subject=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&body=This is an email body shared directly from an Ionic app using URL or links. This can be used in any web app.',
      href: 'mailto:info@mit.de?subject=[SUBJECT]&body=[BODY]'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
